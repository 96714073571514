import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "F:/reactKwik/src/layouts/index.js";
import Button from '../components/button';
import BG from '../components/bg';
import styled from 'styled-components';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <BG mdxType="BG" />
    <div style={{
      maxWidth: '650px',
      margin: '0px auto'
    }}>
      <Title mdxType="Title">Kwikswap Bug Bounty</Title>
      <center>
  <Button style={{
          color: 'white'
        }} href="mailto:admin@kwikswap.org" mdxType="Button">
    Submit a report
  </Button>
      </center>
      <br />
      <br />
      <h1 {...{
        "id": "overview",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h1" {...{
          "href": "#overview",
          "aria-label": "overview permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Overview`}</h1>
      <p>{`Open source, on-chain protocols benefit from community member participation in testing and debugging the smart contracts. As the launch of the Kwikswap protocol (“Kwikswap”) approaches, it is beneficial to formalize the program incentivizing those dedicated security engineers who can help make Kwikswap safer (the “Program”). The Program will bolster the professional audits and formal verification Kwikswap has undergone.`}</p>
      <h1 {...{
        "id": "scope",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h1" {...{
          "href": "#scope",
          "aria-label": "scope permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Scope`}</h1>
      <p>{`This Program is limited to the vulnerabilities affecting Kwikswap in the following contracts:`}</p>
      <p><a parentName="p" {...{
          "href": "https://github.com/Kwikswap/kwikswap-v1-core"
        }}>{`Kwikswap Core`}</a>{` `}<br />{`
`}<a parentName="p" {...{
          "href": "https://github.com/Kwikswap/kwikswap-v1-periphery"
        }}>{`Periphery Contracts`}</a></p>
      <p>{`For purposes of the Program, bugs in Periphery Contracts will be considered less severe than those found in Kwikswap V1 Core.`}</p>
      <p>{`The following are not within the scope of the Program:`}</p>
      <ul>
        <li parentName="ul">{`The example contracts and the contracts in the test folder for the Periphery Contracts link set forth above;`}</li>
        <li parentName="ul">{`Any contract removed from the list of contracts in the Periphery Contracts link set forth above (such list may change from time to time without notice);`}</li>
        <li parentName="ul">{`Bugs in any third party contract or platform that interacts with Kwikswap;`}</li>
        <li parentName="ul">{`Vulnerabilities already reported and/or discovered in contracts built by third parties on Kwikswap; and`}</li>
        <li parentName="ul">{`Any already-reported bugs.`}</li>
      </ul>
      <p>{`Vulnerabilities contingent upon the occurrence of any of the following activities also are outside the scope of this Program:`}</p>
      <p>{`Front end bugs;`}</p>
      <ul>
        <li parentName="ul">{`DDOS attack;`}</li>
        <li parentName="ul">{`Spamming;`}</li>
        <li parentName="ul">{`Automated tools; and`}</li>
        <li parentName="ul">{`Compromising or misusing third party systems or services.`}</li>
      </ul>
      <h1 {...{
        "id": "program-rewards",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h1" {...{
          "href": "#program-rewards",
          "aria-label": "program rewards permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a><strong parentName="h1">{`Program Rewards`}</strong></h1>
      <p>{`Severity of bugs will be assessed under the `}<a parentName="p" {...{
          "href": "https://www.first.org/cvss/calculator/3.0"
        }}>{`CVSS Risk Rating`}</a>{` scale, as follows:`}</p>
      <ul>
        <li parentName="ul">{`Critical (9.0-10.0): Up to $40,000`}</li>
        <li parentName="ul">{`High (7.0-8.9): Up to $10,000`}</li>
        <li parentName="ul">{`Medium (4.0-6.9): Up to $2,000`}</li>
        <li parentName="ul">{`Low (0.1-3.9): Up to $1,000`}</li>
      </ul>
      <p>{`In addition to assessing severity, rewards will be considered based on the impact of the discovered vulnerability as well as the level of difficulty in discovering such vulnerability.`}</p>
      <p>{`Prior to the deployment of Kwikswap V1 to the Ethereum mainnet, which is expected to occur in Dec 2020, successful bug reporters will receive a 20% bonus on their bounty pay out. This is to incentivize hackers to come forward before launch.`}</p>
      <h1 {...{
        "id": "disclosure",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h1" {...{
          "href": "#disclosure",
          "aria-label": "disclosure permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a><strong parentName="h1">{`Disclosure`}</strong></h1>
      <p>{`Any vulnerability or bug discovered must be reported only to the following email: `}<a parentName="p" {...{
          "href": "mailto:admin@Kwikswap.org"
        }}>{`admin@Kwikswap.org`}</a>{`; must not be disclosed publicly; must not be disclosed to any other person, entity or email address prior to disclosure to the `}<a parentName="p" {...{
          "href": "mailto:admin@Kwikswap.org"
        }}>{`admin@Kwikswap.org`}</a>{` email; and must not be disclosed in any way other than to the `}<a parentName="p" {...{
          "href": "mailto:admin@Kwikswap.org"
        }}>{`admin@Kwikswap.org`}</a>{` email. In addition, disclosure to `}<a parentName="p" {...{
          "href": "mailto:admin@Kwikswap.org"
        }}>{`admin@Kwikswap.org`}</a>{` must be made promptly following discovery of the vulnerability.
Please include as much information about the vulnerability as possible, including:`}</p>
      <ul>
        <li parentName="ul">{`The conditions on which reproducing the bug is contingent.`}</li>
        <li parentName="ul">{`The steps needed to reproduce the bug or, preferably, a proof of concept.`}</li>
        <li parentName="ul">{`The potential implications of the vulnerability being abused.`}</li>
      </ul>
      <p>{`A detailed report of a vulnerability increases the likelihood of a reward and may increase the reward amount.`}</p>
      <p>{`Anyone who reports a unique, previously-unreported vulnerability that results in a change to the code or a configuration change and who keeps such vulnerability confidential until it has been resolved by our engineers will be recognized publicly for their contribution, if agreed.`}</p>
      <h1 {...{
        "id": "eligibility",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h1" {...{
          "href": "#eligibility",
          "aria-label": "eligibility permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a><strong parentName="h1">{`Eligibility`}</strong></h1>
      <p>{`To be eligible for a reward under this Program, you must:`}</p>
      <ul>
        <li parentName="ul">{`Discover a previously unreported, non-public vulnerability that would result in a loss of or a lock on any ERC-20 token on Kwikswap (but not on any third party platform interacting with Kwikswap) and that is within the scope of this Program.`}</li>
        <li parentName="ul">{`Be the first to disclose the unique vulnerability to `}<a parentName="li" {...{
            "href": "mailto:admin@Kwikswap.org"
          }}>{`admin@Kwikswap.org`}</a>{`, in compliance with the disclosure requirements above.`}</li>
        <li parentName="ul">{`Provide sufficient information to enable our engineers to reproduce and fix the vulnerability.`}</li>
        <li parentName="ul">{`Not engage in any unlawful conduct when disclosing the bug to `}<a parentName="li" {...{
            "href": "mailto:admin@Kwikswap.org"
          }}>{`admin@Kwikswap.org`}</a>{`, including through threats, demands or any other coercive tactics.`}</li>
        <li parentName="ul">{`Not exploit the vulnerability in any way, including through making it public or by obtaining a profit (other than a reward under this Program).`}</li>
        <li parentName="ul">{`Make a good faith effort to avoid privacy violations, destruction of data, interruption or degradation of Kwikswap.`}</li>
        <li parentName="ul">{`Submit only one vulnerability per submission, unless you need to chain vulnerabilities to provide impact regarding any of the vulnerabilities.`}</li>
        <li parentName="ul">{`Not submit a vulnerability caused by an underlying issue that is the same as an issue on which a reward has been paid under this Program.`}</li>
        <li parentName="ul">{`Be at least 18 years of age.`}</li>
        <li parentName="ul">{`Not be subject to US sanctions or reside in a US-embargoed country.`}</li>
        <li parentName="ul">{`Not be one of our current or former employees, vendors, or contractors or an employee of any of those vendors or contractors.`}</li>
        <li parentName="ul">{`Comply with all the eligibility requirements of the Program.`}</li>
      </ul>
      <h1 {...{
        "id": "other-terms",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h1" {...{
          "href": "#other-terms",
          "aria-label": "other terms permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a><strong parentName="h1">{`Other Terms`}</strong></h1>
      <p>{`All reward decisions, including eligibility for and amounts of the rewards and the manner in which such rewards will be paid, are made at our sole discretion.`}</p>
      <p>{`The terms and conditions of this Program may be altered at any time.`}</p>
      <br />
      <br />
      <br />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      